import React from "react"
import { Link } from "gatsby"
import Logo from "../images/Logo_III.svg"



const Footer = () => {
    return (
        <div className="footer py-12">
        <div  className="sm:w-3/4 sm:ml-1/8 xs:px-4 sm:px-0">
            <div className="xl:grid  xl:grid-cols-4 xl:gap-2 font-opensans">
                <div className="xl:col-span-2">
                        <div className="font-montserrat font-bold text-white xs:text-xs md:text-sm lg:text-base flex flex-wrap">
                                    <Link to="/about"> <p className="xs:pt-2 sm:pt-0 pr-4">About</p></Link>
                                    <Link to="/contact"><p className="xs:pt-2 sm:pt-0 pr-4">Contact</p></Link>
                                    <Link to="/diet"><p className="xs:pt-2 sm:pt-0 pr-4">Diet</p></Link>
                                    <Link to="/health_fitness"><p className="xs:pt-2 sm:pt-0 pr-4">Health & Fitness</p></Link>
                                    <Link to="/projects"> <p className="xs:pt-2 sm:pt-0 pr-4">Projects</p></Link>
                                    <Link to="/worldtoday"><p className="xs:pt-2 sm:pt-0 ">World Today</p></Link>
                                    
                                </div>
                            <div className="flex space-x-10 xl:space-x-16 xs:text-xs md:text-sm xs:mt-8 xl:mt-4">
                                <div className="text-gray-300">
                                    <Link to="/biblestudy"> <p className="font-montserrat font-bold text-white pb-1">Bible Study</p></Link>
                                    <Link to="/biblestudy/theword"> <p className="py-1">The Word</p></Link>
                                    <Link to="/biblestudy/prophecy"><p className="py-1">Prophecy</p></Link>
                                    <Link to="/biblestudy/channel"><p className="py-1">ReaCh Channel</p></Link>
                                </div>
                                <div className="text-gray-300">
                                    <Link to="/family"><p className="font-montserrat font-bold text-white pb-1">Family</p></Link>
                                    <Link to="/family/mw"><p className="py-1">Men & Women</p></Link>
                                    <Link to="/family/youth"><p className="py-1">Youth</p></Link>
                                    <Link to="/family/kids"><p className="py-1">Kids</p></Link>
                                </div>
                                <div className="text-gray-300">
                                    <Link to="/lifestyle"><p className="font-montserrat font-bold text-white pb-1">LifeStyle</p></Link>
                                    <Link to="/lifestyles/country"><p className="py-1">Country Living</p></Link>
                                    <Link to="/lifestyles/faithwalks"><p className="py-1">Faith Walks</p></Link>
                                    <Link to="/devotions"><p className="font-montserrat font-bold text-white pb-1">Morning Devotion</p></Link>
                                </div>
                            </div>
                    </div>
                    <div className="text-white font-opensans xl:space-y-4 text-sm xs:flex flex-wrap xs:mt-8 xl:mt-0 xl:block">
                        <div className="pr-10 xl:flex xl:space-x-8">
                            <p className="font-montserrat font-bold">Email</p>
                            <p className="text-gray-300 text-sm">info@reachun.org</p>
                        </div>
                        <div className="xs:pt-2 sm:pt-0 pr-10 xl:flex xl:space-x-8">
                            <p className="font-montserrat font-bold">Phone</p>
                            <p className="text-gray-300 text-sm">1 868 643 2455</p>
                        </div>
                        <div className="xs:pt-2 sm:pt-0 xl:flex xl:space-x-4">
                            <p className="font-montserrat font-bold">Address</p>
                            <p className="text-gray-300 text-sm">#36 Evelyn Trace <br/> Tumpuna Rd, Arima, <br/>Trinidad</p>
                        </div>
                    </div>
                    
                    <div className="text-white xs:mt-8 xl:mt-0">
                     <p className="font-montserrat font-bold text-2xl mb-1">Jesus is Waiting</p>
                    <p className="font-opensans font-sm font-light text-gray-200 mb-4">Behold, I stand at the door, and knock: if any man hear my voice, and open the door, I will come in to him, and will sup with him, and he with me. <span className="text-sm font-bold"> <br/>- Revelation 3:20</span></p>
                    <Link to="/contact"> <button className="font-opensans font-bold border-2 border-gray-300 py-2 px-12 text-base xl:text-sm 2xl:text-base"> Send Us a Message</button></Link> 
                  
                    </div>
            </div>

         <div className="sm:flex mt-20 sm:space-x-10 md:space-x-12 items-center">
         <div>
            <Link to="/">
            <img src={Logo} alt="Logo" className="xs:pb-8 sm:pb-0 w-64" />
            </Link>
         </div>
          <div>
            <div className="flex text-white text-xs pb-1">
                <Link to="/terms"><p className="border-r border-gray-300 pr-2">Terms of Use</p></Link>
                <Link to="/privacy"> <p className="border-r border-gray-300 px-2">Privacy Policy</p></Link>
                <Link to="/disclaimer">  <p className="border-r border-gray-300 px-2">Disclaimer</p></Link>
                <Link to="/sitemap.xml">  <p className="pl-2">Sitemap</p></Link>
            </div>
            <div className="font-opensans text-gray-300 text-xs pt-1">copyright &copy; 2021. Real Christianity Unplugged. All Rights Reserved.</div>
          </div>
         
         </div>
        
        </div>
        </div>
    )
}
export default Footer
