import React from 'react';
import { graphql } from 'gatsby'
import { Helmet } from "react-helmet"
import Layout from "../components/LayoutAlt.js"
import Social from "../components/social.js"


   const Devotion = ({ data }) => {

       return(
         <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Preaching the Everlasting Gospel contained in the 3 Angels Messages ?" />
                <title>ReaCH | About Us</title>
                <link rel="canonical" href="https://reachun.org/about" />
                <script defer src='https://www.buzzsprout.com/1818923.js?container_id=buzzsprout-large-player-1818923&player=large' type='text/javascript' charset='utf-8' ></script>
            </Helmet>

             <section className="sm:w-3/4 sm:ml-1/8  mt-10 xl:mt-20 mb-20 xs:p-4 sm:p-0">
                 <h1 className="font-playfairdisplay font-bold text-orange-dark text-7xl">Your Morning Devotions</h1>
                 <section className="ml-1/20">
                 <p className="font-montserrat font-bold text-blue-dark text-4xl  mt-2">Fight your Fears</p>
                 <p className="font-opensans font-bold text-blue-dark text-2xl mt-20">Jeremiah 29:11-13</p>
                 <p className="font-opensans text-lg text-gray-700 leading-relaxed mt-4"><span className="font-bold text-xl">11</span> For I know the thoughts that I think toward you, saith the Lord, thoughts of peace, and not of evil, to give you an expected end.<br/>
                 <span className="font-bold text-xl">12</span> Then shall ye call upon me, and ye shall go and pray unto me, and I will hearken unto you.<br/>
                 <span className="font-bold text-xl">13</span> And ye shall seek me, and find me, when ye shall search for me with all your heart.</p>
                 </section>

             <section className="mt-20 ml-1/20">
                
                 {data.allStrapiPrayers.edges.map(pray => (
                     <div className="" key={pray.node.id}>
                     <div className="grid grid-cols-1 xl:grid-cols-5 gap-4 " >
                        <div style={{backgroundImage: `url(${pray.node.image.url})`, backgroundSize: "cover"}} className="xs:h-112 xl:h-120 sm:h-120 xl:col-span-3 pt-16 2xl:pt-32">
                            <div className="xs:mx-4 sm:mx-10 2xl:mx-32">
                            <p className="font-playfairdisplay font-bold text-white xs:text-4xl sm:text-5xl">{pray.node.salutatiion}</p>
                            <p className="font-opensans font-light text-white xs:text-base sm:text-xl leading-relaxed mt-10">{pray.node.fromtheheart}</p>
                            <p className="font-playfairdisplay  text-white xs:text-xl sm:text-3xl mt-4">{pray.node.authority}</p>
                            <p className="font-playfairdisplay  text-white xs:text-xl sm:text-2xl mt-2">{pray.node.letitbeso}</p>
                            </div>
                            </div>
                            <div className="xl:col-span-2">
                                <div id='buzzsprout-large-player-1818923'></div>
                                <div className="font-playfairdisplay font-bold text-blue-dark text-xl  space-y-4">
                                    <p>Now is not a time of fear but a time of Prayer.</p>
                                    <p> Now is not the time to be fearful but faithful.</p>
                                    <p>The Lord is still waiting to be gracious, Have a blessed Day!</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        ))}
                 
             </section>
             </section>
             <Social />
         </Layout>

       )
   }
   export const query = graphql`
   query PrayersPageQuery{
   allStrapiPrayers(limit: 1, sort: {fields: date, order: DESC}) {
    edges {
      node {
        authority
        date(formatString: "LL")
        fromtheheart
        id
        letitbeso
        salutatiion
        image{
            url
        }
      }
    }
  }
}
`
export default Devotion