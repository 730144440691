import React from "react"
import { StaticQuery, graphql } from "gatsby"

const Social = () => {
  return (
    <div>
      <StaticQuery
        query={graphql`
          query Social {
            allStrapiSocials(sort: { fields: key, order: ASC }) {
              edges {
                node {
                  mediatag
                  mediaicon
                  path
                  id
                  key
                }
              }
            }
          }
        `}
        render={data => (
          <div className="text-center text-gray-700 my-20">
            <p className="mb-4 text-2xl tracking-widest ">Follow Us on</p>
            {data.allStrapiSocials.edges.map(media => (
              <div className="inline-block" key={media.node.key}>
                <a
                  href={media.node.path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={media.node.mediaicon}></i>
                </a>
              </div>
            ))}
          </div>
        )}
      />
      
    </div>
  )
}

export default Social
