import React from "react"
import Header from "./HeaderAlt.js"
import Footer from "./Footer.js"

import  "../styles/global.scss"
import  "../styles/select-css.css"
import { pageBody, content, base } from "./layout.module.scss"

const Layout = props => {
  return (
    <div className={pageBody}>
      <div key="1" className={content}>
        <Header />
        <div>{props.children}</div>
      </div>
      <div key="2" className={base}>
        <Footer />
      </div>
    </div>
  )
}

export default Layout