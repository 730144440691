import React from "react"

import Nav from "./NewNav.js"

const Header = () => {
    return (
        <div className="">
           <Nav />
        </div>
    )
}
export default Header
